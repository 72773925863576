.wrapper{
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }
}

.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 260px;
    left: 0;
    z-index: 1030;

    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 75px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;
        background: #fff;
        border-right: 1px solid #e6edef;

        .dropdown .dropdown-backdrop{
          display: none !important;
        }

        .navbar-form{
            border: none;
        }
    }

    .logo-img{
      width: 35px;
      height: 35px;
      display: block;
      margin-left: 2px;
      position: absolute;
      top: 12px;
        img{
            width: 35px;
        }
    }

    .navbar-minimize{
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1;

      @extend .animation-transition-general;
    }
    .logo-tim{
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img{
          width: 60px;
          height: 60px;
      }
    }

    .nav{
        margin-top: 20px;
        display: block;

        .caret{
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li{
            > a + div .nav li > a{
                margin-top: 7px;
            }

            > a{
                margin: 10px 15px 0;
                border-radius: $btn-round-radius;
                color: $white-color;
                display: block;
                text-decoration: none;
                position: relative;
                text-transform: uppercase;
                cursor: pointer;
                font-size: $font-size-mini;
                padding: 10px 8px;
                line-height: $line-height-nav-link;
            }

            &:first-child > a{
                margin: 0 15px;
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a{
                background-color: $opacity-1;

                i{
                  color: $white-color;
                }
                p{
                  color: $white-color;
                }
            }

            &.active > a,
            &.active-pro > a{
              background-color: #6a46ec;
              // box-shadow: $box-shadow;
              i{
                color: $danger-color;
              }
            }
            :hover &.active-pro > a,
            :focus &.active-pro > a{
              background-color: $white-color;
              box-shadow: $box-shadow;
              color: $danger-color;
              i{
                color: $danger-color;
              }
            }
        }

        p{
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            font-weight: 500;
            text-transform: capitalize;
            color: #222222;
            font-size: 16px;
        }

        i{
            font-size: 20px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: #222;
            position: relative;
        }
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: 1;
        }
    }

    .logo{
        position: relative;
        // padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;
        background: #fff;
        border-right: 1px solid #e6edef;
        -webkit-box-shadow: 0 0 20px rgb(25 124 207 / 8%);
        box-shadow: 0 0 20px rgb(25 124 207 / 8%);

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }

        a.logo-normal{
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
            border-bottom: 1px solid #e6edef;
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: $opacity-5;

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: $white-color;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after{
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:after{
        @include icon-gradient($default-color);
        z-index: 3;
    }

    &[data-color="blue"]{
      @include sidebar-color($info-color);
    }
    &[data-color="green"]{
      @include sidebar-color($success-color);
    }
    &[data-color="orange"]{
      @include sidebar-color($orange-color);
    }
    &[data-color="red"]{
      @include sidebar-color(#C8C8C8);
    }
    &[data-color="yellow"]{
      @include sidebar-color($warning-color);
    }
}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    position: relative;
    float: right;
    width: $sidebar-width;
    // width: 100%;
    // background-color: $light-gray;
    // background-color: #ebecf1;
    background-color: #f5f7fb;

    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content{
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px;
        background-color: #f6faff;
    }

    .navbar{
        margin-bottom: 0;
    }


    .header{
        margin-bottom: 50px;
    }
}


.perfect-scrollbar-on{
  .sidebar,
  .main-panel{
      height: 100%;
      max-height: 100%;
  }
}


@media (min-width: 991px) {
  .sidebar{
    display: block;
    // box-shadow: $sidebar-box-shadow;
  }
}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  // @include panel-gradient($black-color);
  background-color: #f6faff;
  position: relative;
  overflow: hidden;

  .header{
    .title{
      color: $white-color;
    }
    .category{
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a{
        color: $white-color;
      }
    }
  }
}

.logo-normal {
	img {
		max-width: 200px;
	}
}

.sidebar[data-color=red] {
	.nav {
		li.active {
			>a {
				background-color: #24695c;
        color: #d0efe9;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        border-radius: 10px;
			}
		}
	}
}
.off-canvas-sidebar[data-color=red] {
	.nav {
		li.active {
			>a {
				background-color: #24695c;
        color: #d0efe9;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        border-radius: 10px;
			}
		}
	}
}

.sidebar[data-color=red] {
	.nav {
		li.active {
			>a {
				p {
					color: #d0efe9;
				}
				i {
					color: #d0efe9;
				}
			}
		}
	}
}

.sidebar {
	.nav {
		li {
			&:hover {
				a {
					background-color: #24695c !important;
					border-radius: 10px;
				}
				i {
					color: #d0efe9 !important;
				}
				p {
					color: #d0efe9 !important;
				}
			}
		}
	}
}

.main-panel .navbar {
  margin-bottom: 0;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 20px rgb(25 124 207 / 8%);
  box-shadow: 0 0 20px rgb(25 124 207 / 8%);
  padding: 18px 0;
  position: fixed;
}

.panel-header-sm{
  height: 135px;
}

.panel-header-lg{
  height: 380px
}
